<template>
    <uw-content title="供应商" padding="10px">

        <!-- 菜单 -->
        <template slot="suffix">
            <el-button type="primary" size="mini" @click="$refs.dialogCreate.Open()">新建供应商</el-button>
        </template>

        <!-- 表格 -->
        <uw-table>

            <!-- 检索 -->
            <template slot="search">
                <el-input @input="$lodash.debounce(TableSearch, 500)" v-model="search.name" clearable  size="mini" placeholder="供应商名称"></el-input>
            </template>

            <!-- 按钮 -->
            <template slot="button">
                <el-button type="primary" size="mini" @click="$refs.xTable.print()" >打印</el-button>
                <el-button type="primary" size="mini" @click="$refs.xTable.openExport()" >导出</el-button>
            </template>

            <!-- 表数据 -->
            <template slot="data">
                <vxe-table
                    ref="xTable"
                    :data="table"
                    :loading="loading"
                    :export-config="{}"
                    :print-config="{}"
                    :seq-config="{startIndex: page.size * (page.current - 1)}"
                    height="100%">

                    <!-- 序号 -->
                    <vxe-column width="60" type="seq" title="序号"></vxe-column>

                    <!-- 供应商名称 -->
                    <vxe-column min-width="240" field="name" title="供应商名称">
                        <template #default="{ row }">
                            <el-link @click="$refs.drawerDetails.Open(row.id)" type="primary">{{ row.name }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 基础信息 -->
                    <vxe-column min-width="100" field="level" title="供应商级别"></vxe-column>
                    <vxe-column min-width="80" field="contacts" title="联系人"></vxe-column>
                    <vxe-column min-width="120" field="phone" title="联系方式"></vxe-column>
                    <vxe-column min-width="120" field="notes" title="备注"></vxe-column>
                    <vxe-column min-width="120" field="address" title="联系地址"></vxe-column>

                    <!-- 系统信息 -->
                    <vxe-column width="140" field="updated_at" title="更新时间"></vxe-column>
                    <vxe-column width="80" field="updated_user.name" title="更新人"></vxe-column>
                    <vxe-column width="140" field="created_at" title="创建时间"></vxe-column>
                    <vxe-column width="80" field="created_user.name" title="创建人"></vxe-column>

                </vxe-table>
            </template>

            <!-- 分页 -->
            <template slot="pager">
                <vxe-pager :total="page.total" :page-size.sync="page.size" :current-page.sync="page.current" @page-change="TableSearch()"></vxe-pager>
            </template>
            
        </uw-table>

        <!-- 组件 -->
        <ims-supplier-create ref="dialogCreate" @onChange="TableSearch()" />
        <ims-supplier-drawer ref="drawerDetails" @onChange="TableSearch()" />
    </uw-content>
</template>

<script>
export default {
    data () {
        return {
            // 窗口
            loading: false,

            table: [],

            // 分页
            page: {
                total: 0,
                size: 50,
                current: 1,
            },

            // 检索
            search: {
                name: null
            },

            // 排序
            order: {
                method: 'desc',
                column: 'id'
            },
        }
    },

    mounted () {
        this.TableSearch()
    },

    methods: {

        // 供应商 - 查询
        TableSearch() {
            // 切换加载状态
            this.loading = true

            this.$http.post(
                '/9api/ims/supplier/search',
                {
                    page: this.page,
                    search: this.search,
                    order: this.order,
                    append: ['created_user', 'updated_user']
                }
            ).then(rps => {
                
                // 更新数据
                this.page.total = rps.data.total
                this.table = rps.data.body

                // 页码溢出
                if (this.table.length == 0 && this.page.current != 1) {
                    this.page.current = 1
                    this.TableSearch()
                }

                // 切换加载状态
                this.loading = false
            })
        }
    }
}
</script>